.boundary-element{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }
  .boundary-text{
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }