@font-face {
  font-family: "Roboto Regular";
  src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  }

@font-face {
  font-family: "Roboto Bold";
  src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  }
@font-face {
  font-family: "Roboto Medium";
  src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  }

body {
  margin: 0;
  font-family: 'Roboto Regular' !important;
}
.Roboto-Bold{
  font-family: 'Roboto Bold' !important;
}
.Roboto-Medium{
  font-family: 'Roboto Medium' !important;
}
.Roboto-Regular{
  font-family: 'Roboto Regular' !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overflow-visible{
  overflow: visible !important;
  padding-right: 0px !important;
}