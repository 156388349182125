// STB Base
$small: 500px;

.closed-sidebar p {
    text-indent: unset;
    text-align: left;
}
.validationerror {
    color: #ff9a9a  !important;
}
.login-container{
    .left-panel{
        // display: flex;
        position: relative;
        // justify-content: center;
        // align-items: center;
        .login-logo{
            margin-top: 0px !important;
        }
    }
    .right-panel{
        .form-wraper{ 
            margin-top: 5% !important; 
            label {font-size: 14px;}
            .create-account {
                font-size: 14px;
                color: #FFFFFF !important;
            }
        }
        .card-title{
            font-weight: 500;
        }
    }
}

input,
input::placeholder {
    font-size: 14px;
}

.breadcrumbs{
    width: 100%;
    margin-bottom: 0.4rem;
    ul{
        list-style: none;
        margin:0;
        li{
            width: auto;
            color: #889197;
            cursor: pointer;
            font-size: 0.9rem;
            span{
                margin: 0 0.3rem 0 0.1rem;
           }
           a{
            color: #889197;
           }
       }
        li:nth-last-child(1) {
            color: #5e03c7;
            cursor:inherit;
       }
   }
}

@media screen and (max-width: $small) {
    .login-container{
        display: block;
    .left-panel{
        width: 100%; 
        .login-logo{
            margin-top: 6%;  
            max-width: 200px;
        }
    }
    .right-panel{
        width: 100%;
        .form-wraper{
            margin-top: 6% !important; 
            width: 90%;
            padding: 10px 0px;
        }
    }
}
  }

  .inactive {
    color: #b194d1;
    background:#fff;
    font-size: 12px;
    border-radius: 4px;
  }
  .active-mfa{
    background: #e10092;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
  }
  .has-search{
    .form-control-feedback {
        top: 36px !important;
    }
} 

.Frg-pwd{
    margin-top:-20px;
    p:hover {
        text-decoration: underline;
      }
}

.login-left{
    padding-top:3%;
}


.landing-page {
    .last-update{
        p{
            font-size: 14px;
        }
    }
    canvas{
        height: 34vh !important;
    }
}
.create-account-bold{
    font-weight: 500 !important;
    color: #FFFFFF;
}
::placeholder {
    color: #BFBBBB !important;
  }