
  .accordian-details{
    background-color: #FBF8FE;
  }
  .installedapps-acordian{
    height: 100vh;
    overflow-y: auto !important;
    margin-top: 20px !important;
    div{
      margin: 0px !important;
        box-shadow: none;
        color: #000;
    }
  }

  .accordiantitle{
    text-transform: capitalize;
    font-size: 14px !important;
    color: #000;
  }
  .accordiansubtitles{
    font-weight: 500 !important;
  }
  .wordbreakall{
    word-break: break-all;
  }
  @media only screen and (min-width: 992px) and (max-width: 1350px)  {
    .andoridfnt{
      font-size: 12px !important;
    }
    .accordiantitle{
      font-size: 13px !important;
    }
  }