// STB Base

.widget-content{
    color: #6f42c1 !important;
}
.login-container{
    background:#fff;
    height: 100vh;
    display: flex;
        .left-panel{
            width: 50%;
            padding-top: 30px;
            // .image-logo{
            //  background-image: url("../../../assets/utils/images/app-main-logo.png") ;
            //     background-position:center;
            //     background-repeat: no-repeat;
            //     background-size:100% 100%;
            // }
            background-color: #EEE3FB;
            .left-panel-tv-logo{
            width: 419px;
            margin: auto;
            height: auto;
}
.left-panel-tata {
    position: relative;
    z-index: 1;
img{
    width: 200px !important;
}
}
.left-panel-shape img{
    width: 100% !important;
    position: absolute;
    left: 0;
    bottom: 0;
}
        }
        .right-panel{
            width:50%;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2c0556+0,6f26bd+100 */
            background: #2c0556; /* Old browsers */
            background: -moz-linear-gradient(top,  #2c0556 0%, #6f26bd 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  #2c0556 0%,#6f26bd 100%); /* Chrome10-25,Safari5.1-6 */
            background: #360768; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c0556', endColorstr='#6f26bd',GradientType=0 ); /* IE6-9 */
            text-align: center;
            
                p{
                    color:#fff;
                }
                .card-title{
                    text-align: center;
                    color: #fff;
                    text-transform: capitalize;
                    font-size: 28px;
                    margin: 0;
                    font-weight: 400;
                }
            .form-wraper{
                width:73%;
                margin-top:10% !important;
                margin: 0 auto;
                    label{
                        text-align: left;
                        float: left;
                        color: #fff;
                    }
             .check-box{
                position: static;
                float: left;
                margin-top: 4px;
             }       
            .Sign-in{
                width: 100%;
                color: #fff;
                border:1px solid #fff;
                background: none;
                margin-top:60px;
                // height:50px;
                font-size:18px;
                transition:0.5s;
            } 
            .Sign-in:hover{
                background:#e10092 !important;
                color: #fff !important;
                border: 1px solid #e10092;
                transition:0.5s;
            }
            .required {
                color:#FFFFFF;
                font-weight: bold;
                font-size: larger;
            }
            .captcha-image{
                border-radius: 4px;
                width: 200px;
                height: 38px;
            }
            .validationerror {
                color: #e95bb7 !important;
                font-size: 14px;
            }
            .on-off-eye {
                position: absolute;
                right: 25px;
                top: 40px;
                z-index: 1;
                color: #a07dc7;
            }
            .left-arrow-icon{
                color: #fff;
                font-size:medium;
            }
            }
        }
}
.dropdown-menu.show{
        li{
            border-bottom: 1px solid #dadada;
                a{
                    color: #333;
                }
        }
        li:nth-last-child(1){
            border-bottom:none;
        }
}
.home-page{
    .card{
        p{
            font-size: 11px;
            font-weight:400;
            color: #000 !important; 
        }
    }
    .card-header-title{
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 600;
        border-bottom: 1px solid #ccc;
        width: 100%;
        padding: 6px 10px;
        color: #000 !important; 
            
    }
}
.logo-src{
    margin-left: 10px;
}
.app-header__logo {
    background:#fbfafd !important; 
    box-shadow: none;
    .user-wrap{
        display: block;
        width: 204px;
        h3{
            font-size: 16px;
            font-weight: 600;
            color:#000;
            float: left;
            width: 100%;
            padding: 0;
            margin: 0;
        }
        p{
            float: left;
            width: 100%;
            color:#fff;
            margin:5px 0 0 0;
            padding: 0;
            font-size: 13px;
        }
    }
 
}
.closed-sidebar {
    h3{
        text-indent: -999rem;
    }
    p{
        text-indent: -999rem;
        margin-bottom:1px;
    }
}
.cursor-pointer {
    cursor: pointer;
}
.actionDisable {
    pointer-events: none;
}

  .has-search .form-control {
    padding-right: 2.375rem;
    }
    .has-search .form-control-feedback {
    position: absolute;
    width: 1.1rem;
    height: 1.5rem;
    pointer-events: none;
    color: #a07dc7;
    right: 7px;
    top: 46px;
    }

    .search-input{
        position: relative;
        .icon-magnifier{
            position: absolute;
            right: 6px;
            top: 14px;
            font-size: 15px;
            path{
                fill: #BBBBBB;
            }
        }
        .input-search{
            height: 40px;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            padding: 0 30px 0 11px;
            outline-style: none;
        }
    }

    .sub-header {
        h1{
            font-size: 18px;
            font-weight: 600;
            color: #000;
        }
    }
    .createuser {
        background: #fff;
        // color: #e71f9d;
        padding: 6px 24px;
        text-align: center;
        border: 1px solid #e10592;
        border-radius: 4px !important;
        color: #e10592;
        // font-weight: 600;
        &:hover{
            border: 1px solid #e10592;
            background-color: #e10592;
            color: #fff;
            font-weight: bold;
        }
    }
    .Status-btn{
        background: #fff;
        border: 1px solid #cdcdce;
        border-radius: 4px !important;
        transition: none;
        padding: 6px 24px !important;
        font-size: 12px;
        font-weight: 400;
        color: #907fa2;
    }

    .Status-btn:hover,.Status-btn:active {
        border: 1px solid #e4149a;
        background: #e4149a ;
        color: #fff !important;
        border-radius: 4px;
        // font-weight: 400;
    }
    .reset-btn{
        background: #fff;
        border: 1px solid #cdcdce;
        border-radius: 4px !important;
        transition: none;
        padding: 6px 24px !important;
        font-size: 12px;
        font-weight: 400;
        color: #907fa2 !important;
    }

    .reset-btn:hover,.reset-btn:active {
        border: 1px solid #2088ff;
        background: #2088ff !important;
        color: #fff !important;
        border-radius: 4px;
        font-weight: 400;
    }
    .create-btn {
        // border: 1px solid #6b00dd;
        padding: 6px 24px !important;
        background: #913aff;
        color: #fff !important;
        border-radius: 4px;
        font-weight: 400;
        border: none;
        border: 1px solid #913aff;
        &:hover{
            background: #7916ff;
            border: 1px solid #7916ff;
            font-weight: 400;
        }
    }
    .back-btn{
        border: 1px solid #e73baa !important;
        padding: 4px 38px !important;
        color: #e73baa !important;
    }
    .back-btn:hover{
        background-color: #e73baa !important; 
        color: #fff !important;
    }
    .form-control {
        height: 40px;
    }
    .required {
        color: red !important;
    }
  .modal-body{
    h3{
        font-size: 21px;
        font-weight: 400;
        color: #000;
    }
    p{
        font-size: 19px;
        font-weight: 400;
        color: #000;
        margin-bottom: 0px;
    }
  }

    .pop-btns {
        margin-bottom:5px;
        button {
        width: 90px;
        padding: 5px;
        font-size: 14px;
        margin: 2px 7px;
  }
  .btn-success:hover{
    background: #7916ff;
    border: 1px solid #7916ff;
    color: #fff !important;
  }
  .btn-cancel:hover{
    background: #e4149a;
    border: 1px solid #e4149a;
    color: #fff;
  }
}
  .btn-cancel {
    // background-color: #c1c1c1;
    border: 1px solid #cdcdce;
    color: #000;
    transition:0.5s;
  }

  .btn-cancel:hover{
    transition:0.5s;
    // background-color: #ff3131;
  }

  .btn-yes{
    background-color: #ff3131;
  }
//   .btn-yes-user{
//     background-color: #ff3131;
//     margin-left: 0px !important;
//   } 
  .btn-success{
    background: #913aff;
    border: 1px solid #913aff;
    margin-left:0px!important;
  } 

  /*usermanagement filter ui start*/
  .rightSidebar {
    width: 326px;
    color: #fff;
    input{
        width:100%;
        height: 2.5rem;
        margin-bottom: 8px;
        border-radius: 4px;
        padding: 0px 11px;
        border: 1px solid #c7c2c2;
    }

    // .rightSidebar-inner {
    //     padding: 0px 25px 20px;
    // }

    .MuiCheckbox-root{
        color:#c4d7eb;
    }
    .MuiCheckbox-root input:checked + .MuiSvgIcon-root {
        color:#6c03dc;
      }
    .filter-search-blk{
       position: relative;
    }

    .filter-search-blk i{
        position: absolute;
        right: 12px;
        top:13px;
        color: #979797;
    }

    .filter-sec-box{
        background-color: #fff;
        border-radius: 6px;
        padding: 18px 13px 6px;
        margin-bottom: 20px;
    }

     h3 {
        font-size: 22px;
        border-bottom: 1px solid #b7b7b7;
        margin-top: -1px;
        padding: 13px 25px;
    }
    h4{
        font-size: 1rem;
        margin: 16px 0px 9px;
        color: #fff;
    }
    ul{
        // width: 90%;
    padding: 0px;
    list-style: none;
    height: 150px;
    overflow-y: auto;
    margin-top: 5px;
        li{
            display: flex;
            margin-top:5px;
            div{
                margin-top: 9px;
                color: #000;
            }
            .css-10hburv-MuiTypography-root{
                font-size: 14px;
            }
        }
    }
   .filter-btn {
    text-transform: capitalize;
        background: #e20092!important;
        border: 1px solid #e20092!important;
        margin-left: 14px;
        margin-left: 12px;
        min-width: 95px;
        border-radius: 6px;
        padding: 8px 7px;
        cursor: pointer;
    }

    .filter-btn:hover{background: #5e03c7;}


    .filter-reset-btn{
        border: 1px solid #fff!important;
        background: none!important;
        margin-left: 0px;
    }

    .filter-reset-btn:hover{
        background:#8642cf;
    }

}
 /*usermanagement filter ui end*/
.filter {
    padding: 8px 12px;
    background: #fff;
    color: #6b00dd;
    border-radius:21px;
    border: 1px solid #ebebeb;
    &:hover{
        background: #e10091;
        color: #fff;   
    }
}

.actionButtonDisable{
    pointer-events: none;
    // background-color: #9d9d9d !important;
    background-color: #d9d9d9 !important;
    border: 1px solid #d9d9d9 !important;
    color: #787070 !important;
    cursor: no-drop;
}
.actionTextDisable{
    pointer-events: none;
    color: grey !important;
}
.defaultCursor {
    cursor: default !important;
    pointer-events: none !important;
}
.metadataevent {
    opacity: 0.5;
    cursor: default !important;
    pointer-events: none !important;
}
.text-transformnone {
    text-transform: none !important;
}
 .MuiDrawer-paper {
    // background: #803cc9;
    background: #7118cf !important;
    // background: linear-gradient(to bottom, #803cc9 0%, #b371f9 100%);
}
.mapicon{
    color: #9863d6;
    font-size: 1.3rem;
    cursor: pointer;
    height: 1.6em !important;
    width: 1.6em !important;
    padding: 3px;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2); 
    border-radius: 10px
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2); 
  }
  //popup image style
  .image-style{
    width: 60px;
  }
  .clearboth{
    clear: both;
  }
  .entitlement {
    // max-height: 100vh !important;
    height: 100vh;
    overflow-y: auto;
  }
  .h-100per{
    height: 100%;
  }
  .arrow-color{
    color: #000000 !important;
    font-weight: 1000 !important;
  }
  .add-button{
    .create-btn{ 
        min-width: 113px;
        margin-right: 15px;
    }
  }
  

.input-container input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
}

// date icon start
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
.sd-container {
    position: relative;
    float: left;
  }
  .open-button {
    position: absolute;
    top: 34px;
    right: auto;
    right: 30px;
    width: 25px;
    height: 25px;
    pointer-events: none;
  }
  
  .open-button button {
    border: none;
    background: transparent;
  }
  // date icon end
//   .clander-bg{
//     margin-top: 10px;
//   }
  .top-right-filter{
    // float: right;
    // margin-bottom:15px;
    display: flex;
    justify-content: end;
    align-items: center;
    .Status-btn{
        background: #fff;
        border-radius: 4px;
        color: #9B9B9B;
        margin: 0 14px 14px 0;
        float: left;
        min-width: 94px;
        line-height: 20px;
        border: none;
        border: 1px solid #fff !important;
    }
    .Status-btn:hover{
        color:#E10592 !important;
        border: 1px solid #E10592 !important;
        background:#fff;
    }
    .Status-btn-active{
        color: #E10592 !important;
        border: 1px solid #E10592 !important;
    }
    .clander-bg{
        width: 40px;
        height: 40px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #6B00DD;
        background: #fff;
        text-align: center;
        line-height: 24px;
        margin: 0px;
    }
    .filter-application-level:hover{
        background: #e10092;
        color: white;
        border: none;
    }
    
}
.systemerrors{
    .MuiAccordion-root{
        margin-bottom: 5px !important;
        margin-top: 0px !important;
        .MuiAccordionSummary-root{
            .Mui-expanded{
                margin: 0px;
            }
        }
    }
    .accordian-details{
        padding: 0px 20px 8px;
    }
    .tabulardataheader{
        :nth-child(2){
            width: 33%;
        }
    }
}

.Toastify__toast-body > div:last-child{
    padding-right: 16px;
}

.app-main{
    .app-header__mobile-menu{
        .hamburger-box{
            display: none;
        }
    }}

//
// .css-fql1nq-MuiButtonBase-root-MuiCheckbox-root {
//     color: #c4d7eb !important;
// }
.filter-checkbox li span{
    color: #c4d7eb !important;
}
.filter-checkbox li span input:checked + .MuiSvgIcon-root{
    color: #6c03dc;
}
.footer-alignment{
    justify-content: center;
    align-items: center;
}
// ul li::before {
//     content: "\2022";
//     color: red; /* or whatever color you prefer */
//   }
.stb-level-background{
    background-color: #ffffff;
}
.remove-nth-border table tbody tr:nth-last-child(1) td{
    border: none !important;
}

.customLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
.application-level-table{
    table thead tr th{
        background: #fbfbff;
        font-weight: bold;
        color: #000;
    }
    table tbody tr td{
        color: #000;
    }
    table tbody tr:hover{
        background: #fbfbff;
    }
    .row-hover tr:hover{
        // background: #6B00DC;
        td{
            color: #6B00DC;
        }
    }
}
.app-sidebar__inner{
 .vertical-nav-menu{
    margin-bottom: 10px; 
 }
}

@media only screen and (min-width: 1351px)  {
    .eventscaptured{
        right: 395px !important;
        top: 70px !important;
    }
}
.modal-body{
.fnt-12px{
    font-size: 12px;
}
}

  //Media queries
  @media only screen and (min-width: 992px) and (max-width: 1350px)  {
    .app-header{
    height: 50px;
    }
    .closed-sidebar .app-sidebar {
        width:50px;
        min-width: 50px;
    }
    .closed-sidebar:not(.closed-sidebar-mobile) .app-header .app-header__logo {
        width:50px;
     }
     .app-header__logo{
        padding: 0 1rem;
     }
     .vertical-nav-menu .metismenu-item{
        padding-left: 10px !important;
     }
     .vertical-nav-menu .metismenu-container .metismenu-link {
        height: 2rem;
        line-height: 35px;
        font-size: 13px;
     }
    //  .app-sidebar.sidebar-text-light .metismenu .metismenu-container .metismenu-link.active {
    //     line-height: 35px;
    //    }
    //    .pe-7s-browser:before { 
    //     width: 16px;
    //     height: 16px;
    //     left: 9px;
    //     top: 9px;
    //   }
    //   .lnr-pilcrow:before{
    //     width: 19px;
    //     height: 19px;
    //     top: 7px;
    //   }
    //   .lnr-indent-increase:before{
    //     width: 19px;
    //     height: 19px;
    //     left: 9px;
    //     top: 9px;
    //   }
    .app-header-right .MuiSvgIcon-root{
        padding: 4px;
    }
    .closed-sidebar.fixed-sidebar .app-main__outer {
        padding-left: 48px;
    }
    // .app-main .app-main__inner {
    //     padding: 15px 30px 0px 20px;
    // }
    .closed-sidebar{
      .failureeventcard {
      .failuredetails {
        .currentdeviation{
            :nth-child(1){
                font-size: 13px !important;
                width: 140px;
          }
        }     
  }
  }
}
.failureeventcard {
    .failuredetails {
      .currentdeviation{
          :nth-child(1){
              font-size: 13px !important;
              width: 80px;
        }
      }     
}
}
  .landing-page {
    .last-update{
        p{
            font-size: 12px !important;
        }
    }
}
.top-right-filter {
.Status-btn{
    font-size: 11px;
}
.clander-bg{
    width: 35px;
    height: 35px;
    svg{
        font-size: 1.3rem;
    }
}
.filter-application-level{
    svg{
        font-size: 1rem;
    }
}
}
.viewmorebtn {
    svg{
        font-size: 15px !important;
    }
    a{
        font-size: 11px;
    }
}
.home-page{
    .card{
        p{
            font-size: 10px;
        }
    }
    .card-header-title{
        font-size: 13px;
    }
}
.counttracking{
    .bg-2{
        margin-top: 0px !important;
    }
    .bg-3{
        margin-top: 0px !important;
    }
    .bg-4{
        margin-top: 0px !important; 
    }
}
.counttracking {
    div{
        height: 25px !important;
        width: max-content !important;
        padding: 5px 0px !important;
        font-size: 10px !important;
    }
    .bg-1,.bg-2,.bg-3,.bg-4,.bg-5{
        padding: 5px 5px!important;
      }
}
.events-label-color{
    font-size: 13px;
   }
   .selectcomponent{
    div{
        font-size: 13px;
        padding: 7px 33px 7px 10px !important;
    }
  }
  .gridviewmenu{
    div{
        font-size: 13px;
    }
  }
  .font-resolution{
    font-size: 13px !important;
  }
  input, input::placeholder{
    font-size: 13px !important;
  }
//   .add-block-button{
//     padding: 7px 10px !important;
//   }
  .text-ellipsis{
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .application-level-table{
    table{
        tbody{
            tr{
                td{
                    font-size: 13px;
                    padding: 10px 16px;
                }
            }
        }
    }
  }
  .sub-header h1{
    font-size: 0.9rem;
  }
  .search-input{
    .icon-magnifier{
        right: 3px;
        top: 11px;
        font-size: 15px;
    }
    .input-search{
        height: 35px;
    }
}
.add-block-button{
    padding: 5px 7px !important;
    svg{
        height: 0.8em;
        width: 0.8em;
    }
}
.stb-color{
    margin-top: -10px;
}
.fixed-header .app-main {
    padding-top:60px; }

    .breadcrumb ul li{
        font-size: 	0.8rem !important;
      }
      .form-inline{
        font-size: 0.8rem;
        .form-control{
            font-size: 0.8rem;
        }
      }
      .createuser{
        font-size: 13px;
        padding: 5px 16px;
      }
      .pending-approval-user{
        font-size: 13px;
        padding: 5px 16px !important;
      }
      .form-control{
        height: 35px;
      }
      .form-select{
        font-size: 13px !important;
      }
      .update{
        font-size: 13px;
      }
      .permission-action{
        .edit-icon {
            width: 35px !important;
            height: 35px !important;
            padding: 8px !important;
        }
      }
      .roles-and-permission{
        .roles-permission-bg{
            h4{
                font-size: 14px !important;
            }
        }
      }
      .icon-resolution{
        svg{
            height: 35px;
            width: 35px;
            padding: 8px;
        }
      }
      .gobtn{
        padding: 7px 11px !important;
      }
      .mapicon{
        height: 1.3em !important;
        width: 1.5em !important;
        padding: 4px;
      }
      .eachtab{
        font-size: 13px !important;
        padding: 10px !important;
        min-height: 40px !important;
      }
      .boxroot{
        header{
            .MuiTabs-root{
                min-height: 41px;
            }
        }
      }
      .App {
        .react-tabs__tab{
            font-size: 13px !important;
            padding: 6px !important;
          }
      }
    .refreshbtn{font-size: 0.8125rem !important;padding: 5px 7px !important;}
    .fnt-14px{font-size: 13px !important;}
    .dth-container {.dth-row {.dth-data {p {font-size: 13px !important; }}}}
    .dth-container{.dth-row {.dth-caidsincas{table {
        td {font-size:13px !important;h4{font-size:14px !important;}}
        th {font-size:13px !important;}
    }}}}
    .installedapps{.installedappslist {img{width: 25px !important;}}}
    .installedapps{.installedappslist{.appdescription {h5{ font-size: 13px !important;}p{font-size: 10px !important;}}}}
    .application-level-table{ 
        table {
        thead {tr{th{font-size: 13px !important;}}} 
        tbody{tr{td{font-size: 13px !important;}}}
    }
    }
    .lnbtype{font-size: 12px !important;}
    .signalheader{font-size: 16px !important;}
    .signalstrengths {p{font-size: 13px !important;}}
    .signalstrengthgraph {span{font-size: 12px !important;}}
    .sinalLevel {h4{font-size: 16px !important;}p{font-size: 12px !important;}}
    .satellitesettings-form{label, input {font-size: 13px !important;}h5{font-size: 15px !important;}}
    .battery_status{font-size: 12px !important;}
    .number-of-channels{font-size: 14px !important;}
    .cachebtn{font-size: 13px !important;}
    .installedapps {.installedappslist {.tifmonitoringimage{width: 30px !important;}}}
    .installedapps {.installedappslist {.myrecords{width: 30px !important;}}}
    .events-control-table {span{font-size: 13px !important;}}
    .events-button-alignment {.control-event-buttons{font-size: 13px !important;padding: 4px 8px;}}
    .stbmode {li{font-size: 13px !important;}}
    .STB-Mode{p{font-size: 13px !important;}}
    .fnt-12px{font-size: 11px !important;}
    .justify-content{justify-content: end !important;}
    
}

  @media only screen and (min-width: 992px) and (max-width: 1100px)  {
    .closed-sidebar{
        .failureeventcard {
        .failuredetails {
          .currentdeviation{
              :nth-child(1){
                  width: 100px;
                  font-size: 13px !important;
            }
          }     
    }
    }
    .home-page{
        .d-none-breadcumb{
            display: block !important;
        }
    }
  }
  .home-page{
    .d-none-breadcumb{
        display: none !important;
    }
}

  .failureeventcard {
      .failuredetails {
        .currentdeviation{
            :nth-child(1){
                width: 55px;
                font-size: 13px !important;
          }
        }     
  }
  }
  .opensiderbar-responsive{
    flex: 0 0 65%;
    max-width: 65%;
}

  }

.stberror-bg{
    height: 100vh;
    background: #fff;
}
.form-select{
    font-size: 14px;
  }
// .user-status {
//     max-width: 90px;    
//     text-align: center;
//     border-radius: 4px;
//     // padding: 5px 10px;
//     width: 70px;
//     color: #fff;
//     height: 25px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.App {
    .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
   
  .react-tabs__tab-list {
    width: 55%;
    margin: 0 auto 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding-left: 0px;
    background-color: #fff;
  }
   
  .react-tabs__tab {
    width: 50%;
    font-size: 14px;
    padding: 10px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-radius: 4px;
  }
   
  .react-tabs__tab--selected {
    font-weight: 600 !important;
    background: #e4008f;
    color:  #fafafa;
  }
   
  .react-tabs__tab--disabled {
    color: "GrayText";
    cursor: default;
  }
   
  .react-tabs__tab-panel {
    display: none;
  }
   
  .react-tabs__tab-panel--selected {
    display: block;
    font-size: 2rem;
    text-align: left !important;
  }
}
.flex-items{
    display: flex;
    justify-content: center;
    align-items: center;
}
.fnt-14px {
    font-size: 14px;
}
.installedAppsWidth{width: 42% !important;}
.installedapps {.installedappslist {.tifmonitoringimage{width: 40px;}}}
.number-of-channels{font-size: 15px;}
.installedapps {.installedappslist {.myrecords{width: 34px;}}}
.STB-Mode{p{font-size: 14px;}}  


//Date picker
.daterangepicker td.in-range{
    background-color: #e9dcf8 !important;
} 
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
    background-color: #fff !important;
    border-color: transparent;
    color: #999;
}
.daterangepicker td.active, .daterangepicker td.active:hover{
    background-color: #7118cf !important;
}
.daterangepicker .drp-buttons .btn-primary{
    background: #e4008f;
    border-color: #e4008f;
    font-weight: normal !important;
}
.daterangepicker .drp-selected{
    font-weight: 600;
}
.fnt-16px{
    font-size: 16px !important;
}
.text-pink{
    color: #e525a1;
}
.text-purple{
    color: #5e03c7;
}
.text-yellow{
    color: #f54607;
}
.justify-content{
    justify-content: center;
}
.actions{
.badge{
    font-size: 10px;
    line-height: 1;
    padding: 4px 4px;
    color: #fff;
    font-weight: normal;
}
.badge-pill{
    background-color: #5e03c7;
}
}
.pe-auto{
    pointer-events: auto;
}
.bg-white {background-color: #fff;}
.clr-black{color: #000;}
.delete-dot{
    padding: 5px;
    background-color:#dac5f2;
    color: #7121ce !important;
    border-radius: 50%;
    display:inline;
    font-size: 35px !important;
  }
  .lh-30{line-height: 30px;}
  .wizardPagination{
    flex-direction: row !important;
    align-items: center;
    justify-content: end;
    padding: 10px;
    .flex-vertical{
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: end;
        .go-button-container{
            display: flex;
            align-items: center;
            input{
                width: 46px;
                &:focus-visible{
                    outline: none;
                }
            }
            span{
                margin-left: 1rem;
                margin-right: 0.5rem;
            }
            .create-btn{
                margin-left: 10px;
                padding: 2.7px 10px !important;
                align-self: baseline;
            }
        }
      }
      nav{
        .Mui-selected {
            background-color: #e10092;
            min-width: 26px;
            height: 26px;
            &:hover{
                background-color: #e10092;
            }
        }
      }
    ul{
        justify-content: end;
    }
  }

  .control-event-hover{
    &:hover{
        background: #F5F5F5;
    }
  }
  .edit-icon {
    color: #5373dc;
  }
  .h-40vh{
    height: 37vh;
  }
  .no-data-found-text{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 16px;
    .my-records {
        width: 300px;
    }
    .bluetooth-device {
        width: 400px; 
    }
    .cdvr-img{
        width: 340px;
    }
  }
  .page-not-found{
    margin: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;
    background-color: #fff;
    img{
        height: 40vh;
    }
    h4{
        font-size: 26px;
        color: #000;
        font-family: 'Roboto Bold';
    }
    p{
        text-align: center !important;
        font-size: 16px;
        color: #000;
        font-family: 'Roboto Regular';
        margin-bottom: 10px;    
    }
  }
  .arrow-right{
    font-size: 18px;
    color: #6b00dd;
  }
  .custom-popup{
    .custom-popup-header{
            display: flex;
            padding: 1rem;
            font-size: 14px;
            border-bottom: 1px solid gray;
            font-weight: bold;
    }
    .custom-popup-body{
        padding: 0 1rem 1rem 1rem;
        .input-label{
            .label{
                margin-bottom: 0px;
                font-size: 14px;
              }
        }
        .radio-box{
            margin: 0 -13px;
            svg{
              font-size: 18px;
              color: #6b00dc;
            }
            .MuiFormControlLabel-label {
              font-size: 14px;
            }
          }
    }
  }
  .legends-outside{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    p{
        margin-right: 5px;
        display: flex;
        align-items: center;
        .round-circle{
            height: 10px;
            width: 10px;
            margin-right: 3px;
            display: block;
            border-radius: 15px;
        }
    }
}
.add-line-through{
    text-decoration: line-through;
    text-decoration-thickness: 0.2em;
}
.arrow-right{
    font-size: 18px;
    color: #6b00dd;
    cursor: pointer;
}

  .stb-graph-header{
    width: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .short-hand-unique-crash {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 500px;
  }
  @media only screen and (min-width: 1350px) and (max-width: 1450px) {
    .home-page .card-header-title {
        font-size: 14px;
    }
}
.dual-list-custom-form{
    input{
        padding: 8px;
    }
    .MuiOutlinedInput-root{
        padding: 3px !important;
    }
  }