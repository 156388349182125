.userbox-list{
  padding: 10px 15px;
  color: #000;
}
a {
    text-decoration: none;
  }
  
  a:hover {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }

  .custom-nav-icon{
    // padding: 6px 2px 2px 2px !important;
    color: #fff !important;
    background: #5e03c7 !important;
    width: 33px;
    border-radius: 50px!important;
    height: 33px;
    margin: 0px 10px;
    text-align: center;
    border-radius: 4px;
    line-height: 31px;
    margin: 0px 9px;
        }
.profile-dropdown{
min-width:13rem;
}
.notification{
  margin-top: 1px !important;
}
.account-circulation{
  border-radius: 51%;
  /* font-size: 1.5rem; */
  color: white !important;
  margin: 0 5px;
  transition: 0.5s;
  width: 25px;
  height: 25px;
  background-color: #8a55a9;
  padding: 1px;
}
.notifybadge {
  span {
    background-color: #ff1d9d;
    height: 17px;
    min-width: 15px;
    font-size: 10px;
    right: 2px;
  }
}