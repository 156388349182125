.form-wraper p{
text-indent: 1px !important;

}
.forgot-password-form{
    justify-content: center !important;
    align-items: center !important;
    // margin-top: 88px !important;
}
.has-search{
    .form-at {
        top: 36px !important;
    }
}
