
.login-container{
    .right-panel{
    height: auto !important;
.form-wraper.signup{
    margin-top:3% !important;
    width: 90% !important;
}
}
.left-panel-tv-logo-signup{
    width: 400px !important;
}
}
