
.login-container{
    .right-panel{
.form-wraper{
    margin-top:5% !important;
}
}
}
.create-password-button{
    border: 2px solid #6d04dd !important;
    border-radius: 4px !important;
    color: #6d04dd !important;
    font-weight: 600;
    padding: 13px !important;
    width: 270px !important;
    &:hover{
        background-color: #e71f9d !important;
        color: #fff!important;
        border: 2px solid #e71f9d !important;
    }
}
.tag-style{
    font-weight: 300 !important;
    font-size: medium !important;
    margin-top: 5px;
}